<template>
  <div>
    Placeholder for build
  </div>
</template>

<script>
export default {
  name: 'AiChatWidget',

  props: [],

  components: {},

  data: function() {
    return {};
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {},

  computed: {},

};
</script>

<style scoped lang="scss">

</style>